import { render, staticRenderFns } from "./OrderCoupon.vue?vue&type=template&id=7b6c8de6&scoped=true&"
import script from "./OrderCoupon.vue?vue&type=script&lang=js&"
export * from "./OrderCoupon.vue?vue&type=script&lang=js&"
import style0 from "./OrderCoupon.vue?vue&type=style&index=0&id=7b6c8de6&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b6c8de6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnIcon: require('/home/php1/m-nuxt/components/YfnIcon.vue').default,YfnButton: require('/home/php1/m-nuxt/components/YfnButton.vue').default,YfnPopup: require('/home/php1/m-nuxt/components/YfnPopup.vue').default})
