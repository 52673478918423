
export default {
    name: 'BagStepper',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        middleEmit: Object,
        info: Object
    },
    data() {
        return {
            rsInfo: {
                quantity: 1
            }
        }
    },
    computed: {},
    watch: {
        info: {
            handler() {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        setData() {
            this.rsInfo = JSON.parse(JSON.stringify(this.info))
        },
        blurStep() {
            if(this.rsInfo.quantity === '') {
                this.rsInfo.quantity = this.info.quantity
            }
        },
        changeStep(e) {
            if(e == this.info.quantity) {
                return
            }
            this.$fnApi.debounce(() => {
                if(!e) {
                    this.$fnApi.debounce(() => {
                        if(e !== '') {
                            this.rsInfo.quantity = 1
                            this.middleEmit.fn('changeStepDelete', this.rsInfo)
                        }
                    }, 10)
                } else {
                    this.$fnApi.debounce(() => {
                        this.middleEmit.fn('updateProductAjax', this.rsInfo)
                    }, 300)
                }
            }, 100)
        },
    },
}
