import { render, staticRenderFns } from "./BagNew.vue?vue&type=template&id=aef5dcec&scoped=true&"
import script from "./BagNew.vue?vue&type=script&lang=js&"
export * from "./BagNew.vue?vue&type=script&lang=js&"
import style0 from "./BagNew.vue?vue&type=style&index=0&id=aef5dcec&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aef5dcec",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnIcon: require('/home/php1/m-nuxt/components/YfnIcon.vue').default,YfnTag: require('/home/php1/m-nuxt/components/YfnTag.vue').default,YfnProduct: require('/home/php1/m-nuxt/components/YfnProduct.vue').default,YfnButton: require('/home/php1/m-nuxt/components/YfnButton.vue').default,YfnPopup: require('/home/php1/m-nuxt/components/YfnPopup.vue').default,YfnImage: require('/home/php1/m-nuxt/components/YfnImage.vue').default})
