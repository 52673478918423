
export default {
    name: 'BagNotify',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        goodsInfo: Object
    },
    data() {
        return {
            hasLogin: true,
            hasRegister: false
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.hasLogin = this.$login()
        // !this.hasLogin && this.getRegisterStatus()
    },
    methods: {
        getRegisterStatus() {
            this.$api.user.getRegisterStatus().then(response => {
                const res = response.result || {};
                this.hasRegister = res != 'sign'
            })
        },
        handleLogin() {
            this.$router.push({
                name: 'Login'
            })
        }
    },
}
