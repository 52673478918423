
export default {
    name: 'BagViewed',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            viewed: {
                visible: false,
                list: []
            },
            params: {
                ids: ''
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
        this.params.ids && this.getHistoryAjax()
    },
    methods: {
        // 获取历史记录
        getHistoryAjax() {
            this.$api.product.getSearchProduct({
                ids: this.params.ids
            }).then(response => {
                const res = response.result || [];
                this.viewed.list = res || []
                this.viewed.list.forEach(p => {
                    p.scoreAverage && (p.scoreAverage = p.scoreAverage || 0 / 10000)
                })
                this.viewed.visible = !!this.viewed.list.length
            })
        },
        //加购成功反馈
        emitAdd(obj){
            this.$emit('emit-add')
        },
        initialSet() {
            let productId = this.$storage.get('product/history') || [];
            this.params.ids = productId.join(',')
        }
    },
}
