
export default {
    name: 'BagRec',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        middleEmit: Object
    },
    data() {
        return {
            visible: false,
            listCompo: { // 列表组件配置
                list: [],
                options: {
                    hasMore: true,
                }
            },
            page: {
                pageSize: 20,
                pageNum: 1,
                totalPage: 1
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.getRecAjax()
    },
    methods: {
        getRecAjax() {
            this.$api.common.getRecV5Common({
                index: 3,
                pageSize: this.page.pageSize,
                pageNum: this.page.pageNum,
            },false).then(response => {
                const res = response?.result || {};
                let list = res.list || []
                list.forEach(p => {
                    const spuModel = p.spuModel || {}
                    Object.keys(spuModel).forEach(key => {
                        p[key] = spuModel[key]
                    })
                })
                this.listCompo.list = [...this.listCompo.list, ...list]
                this.totalPage = res.totalPage
                this.visible = this.listCompo.list.length
            })
        },
        emitAddBag() {
            this.middleEmit.fn('getBagAjax')
        },
        emitBottomLoading(e, callBack) {
            const hasNext = this.totalPage > this.page.pageNum;
            if(e.isIntersecting && hasNext) {
                this.page.pageNum += 1
                this.getRecAjax()
            }
            callBack(!hasNext)
        }
    },
}
