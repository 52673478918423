
export default {
    name: 'BagOut',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        middleEmit: Object,
        out: Object
    },
    data() {
        return {
            currency:{},
            params: {
                outId: '',
                outWishIds: []
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.currency = this.$storage.get('currency') || {}
    },
    methods: {
        getOutParams() {
            let outIds = [],outWishIds = [];
            this.out.list.forEach(item => {
                outIds.push(item.cartId)
                outWishIds.push({
                    cartId: item.cartId,
                    spuId: item.spuId 
                })
            })
            this.params.outIds = outIds.join(',')
            this.params.outWishIds = outWishIds
        },
        setDeleteProductAjax() {
            this.$api.bag.deleteBag({
                ids: this.params.outId
            }).then(response => {
                this.middleEmit.fn('getBagAjax')
                this.$updateBagQuantity()
            })
        },
        setWishProductAjax() {
            this.$api.product.setAddWish({
                moveToCollectParam: this.params.outWishIds
            }).then(response => {
                this.middleEmit.fn('getBagAjax')
                this.$updateBagQuantity()
            })
        },
        handleWishlist() {
            this.$Dialog.confirm({
                message: `${this.$translate('Are you sure to moving the item(s) to wishlist from shopping bag')}?`,
                confirmButtonText: this.$translate('Yes'),
                cancelButtonText: this.$translate('No')
            }).then(() => {
                this.getOutParams()
                this.setWishProductAjax()
            }).catch(() => {})
        },
        handleDelete() {
            this.$Dialog.confirm({
                message: `${this.$translate('Are you sure to delete this item(s)')}?`,
                confirmButtonText: this.$translate('Delete'),
                cancelButtonText: this.$translate('Cancel')
            }).then(() => {
                this.getOutParams()
                this.setDeleteProductAjax()
            }).catch(() => {})
        }
    },
}
