import { render, staticRenderFns } from "./BagOut.vue?vue&type=template&id=087cd1e7&scoped=true&"
import script from "./BagOut.vue?vue&type=script&lang=js&"
export * from "./BagOut.vue?vue&type=script&lang=js&"
import style0 from "./BagOut.vue?vue&type=style&index=0&id=087cd1e7&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "087cd1e7",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnImage: require('/home/php1/m-nuxt/components/YfnImage.vue').default,YfnButton: require('/home/php1/m-nuxt/components/YfnButton.vue').default,YfnPopup: require('/home/php1/m-nuxt/components/YfnPopup.vue').default})
