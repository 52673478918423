
export default {
    name: 'BagProps',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        middleEmit: Object,
        info: Object
    },
    data() {
        return {
            rsInfo: {},
            bagCompo: {
                options: {
                    visible: false,
                    hasImdAddBag: false
                },
                info: {},
                sku: {}
            },
        }
    },
    computed: {},
    watch: {
        info: {
            handler() {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        setData() {
            this.rsInfo = JSON.parse(JSON.stringify(this.info))
            let arr = [];
            if(this.rsInfo.simpleSpec) {
                this.rsInfo.simpleSpec.forEach(p => {
                    arr.push(p.specVal)
                })
                this.rsInfo.rsPropsText = arr.join('/')
            }
        },
        // 更换sku
        handleProps() {
            if(!this.rsInfo.goodsType) return
            if(this.rsInfo.customizeType != 2) {
                this.bagCompo.info.productCode = this.rsInfo.productCode
                this.bagCompo.info.seoUrl = this.rsInfo.seoUrl
                this.bagCompo.sku.skuMId = this.rsInfo.skuId
                this.bagCompo.options.quantity = this.rsInfo.quantity
                this.bagCompo.options.visible = true
                this.bagCompo.options.btnText = this.$translate('Confirm')
            } else {
                this.middleEmit.fn('showCustomize', this.rsInfo)
            }
        },
        emitAddConfirm(obj) {
            const addParams = obj.addParams || {};
            this.middleEmit.fn('updateProductAjax', {
                id: this.rsInfo.id,
                spuId: addParams.spuId,
                skuId: addParams.skuId,
                quantity: addParams.quantity
            })
            this.bagCompo.options.visible = false
        },
        handleProduct() {
            this.$router.push({
                name: 'Product-Seo',
                params: {
                    code: this.rsInfo.productCode,
                    seoUrl: this.rsInfo.seoUrl,
                }
            })
        }
    },
}
