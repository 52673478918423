
export default {
    name: 'BagVip',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        currency: Object,
        middleEmit: Object,
        info: Object,
    },
    data() {
        return {
            rsInfo: {},
            vip: {}
        }
    },
    computed: {},
    watch: {
        info: {
            handler() {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        setData() {
            this.rsInfo = JSON.parse(JSON.stringify(this.info)) || {}
            this.vip = this.rsInfo.vipService || {}
            this.vip.vipQuantity = this.rsInfo.vipQuantity
        },
        // vip删除接口
        deleteVipAjax() {
            this.$api.bag.deleteVip({
                id: this.rsInfo.id
            }).then(response => {
                this.middleEmit.fn('getBagAjax')
            })
        },
        // vip删除
        handleDelete() {
            this.$Dialog.confirm({
                message: `${this.$translate('Are you sure to delete this vip service')}?`,
                confirmButtonText: this.$translate('Yes'),
                cancelButtonText: this.$translate('No')
            }).then(() => {
                this.deleteVipAjax()
            }).catch(() => {})
        }
    },
}
