
export default {
    name: 'BagNew',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        currency: Object,
        info: Object
    },
    data() {
        return {
            rsInfo: {},
            popup: {
                visible: false,
                list: [],
                options: {
                    img: {width: '100px', height: '100px'}
                }
            },
            guide: {
                visible: false,
                hasShowGuide: false, // 新人专享引导是否显示过
            },
            params: {
                id: '',
                style: {}
            }
        }
    },
    computed: {},
    watch: {
        info: {
            handler() {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        setData() {
            this.rsInfo = JSON.parse(JSON.stringify(this.info)) || {}
            this.params.id = this.rsInfo.id
            this.setScrollTo()
        },
        // 获取新人专享商品列表
        getNewAjax() {
            this.$api.bag.getNewList({
                activityId: this.info.activityId
            }).then(response => {
                const res = response.result || [];
                this.popup.list = res
                this.setNewData()
                this.popup.visible = true
            })
        },
        // 更换新人专享商品
        changeNewUserAjax() {
            this.$api.bag.changeNewProduct({
                id: this.params.id,
            }).then(response => {
                this.popup.visible = false
                this.middleEmit.fn('getBagAjax')
            }).catch(() => {
                this.params.id = this.rsInfo.id
            })
        },
        setNewData() {
            this.popup.list.forEach(p => {
                p.rsProduct = {
                    ...p,
                    title: p.productName,
                    pic: p.skuPic,
                }
            })
        },
        // 替换新人专享商品
        handleChange() {
            if(!this.popup.list.length) {
                this.getNewAjax()
            } else {
                this.popup.visible = true
            }
        },
        handleChoose(obj) {
            this.params.id = obj.id
        },
        handleConfirm() {
            if(this.params.id == this.rsInfo.id) {
                this.popup.visible = false
            } else {
                this.changeNewUserAjax()
            }
        },
        // 引导弹窗-------------
        setScrollTo() {
            this.guide.hasShowGuide = !!this.$storage.get('bag/hasShowGuide')
            if(this.guide.hasShowGuide) return
            this.$nextTick(() => {
                const tag = document.querySelector('.BagNew-point');
                tag && this.$VueScrollTo.scrollTo(tag)
                this.setGuideStyle()
            })
        },
        setGuideStyle() {
            const ele = document.querySelector('.BagNew-tag');
            const top = (ele && ele.getBoundingClientRect().top) || 0;
            this.guide.style = {
                top: `${top}px`,
                width: '100%',
                left: 0,
                transform: 'none',
                padding: '0 12px',
                'box-sizing': 'border-box',
            }
            this.guide.visible = true
        },
        setGuide() {
            this.$storage.set('bag/hasShowGuide', true)
            this.guide.visible = false
        }
    },
}
