
export default {
    name: 'BagWish',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            viewed: {
                visible: false,
                list: []
            },
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
    },
    methods: {
        // 获取历史记录
        getHistoryAjax() {
            this.$api.user.getCollectList({
                pageSize: 20,
                pageNum: 1,
                totalPage: 1
            }).then(response => {
                const res = response.result || [];
                this.viewed.list = res.list || []
                this.viewed.list.forEach(p => {
                    p.scoreAverage && (p.scoreAverage = p.scoreAverage || 0 / 10000)
                })
                this.viewed.visible = !!this.viewed.list.length
            })
        },
        //加购成功反馈
        emitAdd(obj){
            this.$emit('emit-add')
        },
        initialSet() {
            this.$login()?
            this.getHistoryAjax():''
        }
    },
}
