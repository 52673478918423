
export default {
    name: 'BagTitle',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        middleEmit: Object,
        info: Object,
        edit: Object,
        goods: Object
    },
    data() {
        return {
            rsInfo: {
                onSelect: true,
                orgOnSelect: true,
                editBlockSelect: false,
            },
            active: {},
            params: {
                invalid: ''
            }
        }
    },
    computed: {},
    watch: {
        info: {
            handler() {
                this.setData()
            },
            deep: true,
            immediate: true
        },
        edit: {
            handler() {
                this.setEditData()
            },
            deep: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        setData() {
            // goodsType -> 0 下架商品 1 普通商品 2 满赠商品 3 赠品 4 闪购商品 5 满折商品 8 满减活动 11 新人专享 12 礼品卡
            const rsInfo = JSON.parse(JSON.stringify(this.info));
            this.rsInfo = {...rsInfo}
            this.active = this.rsInfo.activityAmt || this.rsInfo.flashActivity || this.rsInfo.bmoActivity || this.rsInfo.reducedActivity || {}
            this.active.hasActive = !!this.active.activityId
            this.active.hasTime = this.active?.endsIn > 0
            if(!this.active.activityName) {
                this.active.activityName = this.$translate('Flash Sale')
            }
            this.setSelectData()
        },
        setSelectData() {
            this.rsInfo.onSelect = true
            const list = this.rsInfo.cartItems || [];
            const idArr = [];
            list.forEach(p => {
                if(!p.onSelect) {
                    this.rsInfo.onSelect = false
                }
                idArr.push(p.id)
            })
            this.rsInfo.orgOnSelect = this.rsInfo.onSelect
            this.rsInfo.rsId = idArr.join(',')
        },
        handleChoose() {
            this.rsInfo.onSelect = !this.rsInfo.onSelect
            this.$fnApi.debounce(() => {
                if(this.rsInfo.onSelect === this.rsInfo.orgOnSelect) return
                this.middleEmit.fn('setChooseProduct', {
                    id: this.rsInfo.rsId,
                    onSelect: this.rsInfo.onSelect
                })
            }, 300)
        },
        // 倒计时结束
        finishTime() {
            this.middleEmit.fn('getBagAjax')
        },
        handleActive() {
            // const url = {
            //     2: 'Activity-FreeGifts',
            //     4: 'Activity-FlashSale',
            //     5: 'Activity-FullFold',
            //     8: 'Activity-FullReduce',
            //     11: 'Activity-NewComer',
            // };
            // const name = url[this.rsInfo.goodsType];
            this.$router.push({
                // name,
                name: 'Activity-Tmpl',
                params: {
                    seoUrl: this.rsInfo?.bmoActivity?.seoUrl
                },
                // query: {
                //     activityId: this.rsInfo.activityId
                // }
            })
        },
        // 购物车编辑--------------------
        setEditData() {
            if(!this.edit.hasEdit) return
            const idArr = this.rsInfo.rsId.split(',');
            let editBlockSelect = true;
            // 当前板块选择
            idArr.forEach(p => {
                if(!this.edit.id.includes(p)) {
                    editBlockSelect = false
                }
            })
            this.rsInfo.editBlockSelect = editBlockSelect
            // 是否全选
            this.setEditAllSelect()
        },
        setEditAllSelect() {
            let allIdArr = [];
            let editAllSelect = true;
            this.goods.list.forEach(p => {
                p.cartItems.forEach(c => {
                    allIdArr.push(c.id)
                })
            })
            allIdArr.forEach(p => {
                if(!this.edit.id.includes(p)) {
                    editAllSelect = false
                }
            })
            this.edit.hasAllSelect = editAllSelect
        },
        handleEditChoose() {
            const idArr = this.rsInfo.rsId.split(',');
            const editIdArr = this.edit.id.split(',');
            let newEditArr = [...editIdArr];
            !this.edit.id && (newEditArr = [])
            this.rsInfo.editBlockSelect = !this.rsInfo.editBlockSelect
            if(this.rsInfo.editBlockSelect) {
                idArr.forEach(p => {
                    if(!editIdArr.includes(p)) {
                        newEditArr.push(p)
                    }
                })
            } else {
                newEditArr = editIdArr.filter(p => {
                    return !idArr.includes(p)
                })
            }
            this.edit.id = newEditArr.join(',')
        },
        // set params
        setParams() {
            const list = this.rsInfo.cartItems || [];
            const initialArrId = [];
            list.forEach(p => {
                initialArrId.push(p.id)
            })
            this.params.invalid = initialArrId.join(',')
        },
        // 删除购物车商品
        deleteProductAjax() {
            this.$api.bag.deleteBag({
                ids: this.params.invalid
            }).then(response => {
                this.middleEmit.fn('getBagAjax')
            })
        },
        // 失效商品
        handleDeleteInvalid() {
            this.setParams()
            this.$Dialog.confirm({
                message: `${this.$translate('Are you sure to delete this item(s)')}?`,
                confirmButtonText: this.$translate('Delete'),
                cancelButtonText: this.$translate('Cancel')
            }).then(() => {
                this.deleteProductAjax()
            }).catch(() => {})
        },
    },
}
