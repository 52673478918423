
export default {
    name: 'BagSafePopup',
    props: {
        popup: Object,
    },
    data() {
        return {
            country: {},
            shopping: {
                list: []
            }
        }
    },
    computed: {},
    watch: {},
    created() {
        this.initialSet()
    },
    mounted() {},
    methods: {
        // 跳转运输协议页
        goShipping() {
            this.$router.push({
                name: 'Policy',
                query: {
                    id: '1679369506448H4KdPCGQ'
                }
            })
        },
        // More
        handleRight(obj) {
            if(obj == 1) {
                if(!this.$login()) {
                    this.$router.push({
                        name: 'Login',
                    })
                } else {
                    this.$router.push({
                        name: 'OrderList',
                    })
                }
                return
            }
            if(obj == 2 || obj == 4) {
                let id = obj == 2 ? '1679455454190GMmYwUO3' : '16793696198020vLSBIcB';
                this.$router.push({
                    name: 'Policy',
                    query: {
                        id
                    }
                })
                return
            }
            if(obj == 3) {
                this.$router.push({
                    name: 'ContactUs'
                })
            }
        },
        // 初始化
        initialSet() {
            this.country = this.$storage.get('country')
            this.shopping.list = [{
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1691810649319.png'
            }, {
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1691810668569.png'
            }, {
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1691810674912.png'
            }, {
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1691810663762.png'
            }, {
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1691810680793.png'
            }, {
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1691810749020.png'
            }, {
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1691810686605.png'
            }, {
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1691810698340.png'
            }, {
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1691810706315.png'
            }, {
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1691810732356.png'
            }, {
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1691810716654.png'
            }, {
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1691810744389.png'
            }]
        }
    },
}
