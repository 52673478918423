import { render, staticRenderFns } from "./BagProps.vue?vue&type=template&id=8b022f9a&scoped=true&"
import script from "./BagProps.vue?vue&type=script&lang=js&"
export * from "./BagProps.vue?vue&type=script&lang=js&"
import style0 from "./BagProps.vue?vue&type=style&index=0&id=8b022f9a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b022f9a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnIcon: require('/home/php1/m-nuxt/components/YfnIcon.vue').default,YfnBag: require('/home/php1/m-nuxt/components/YfnBag.vue').default})
